import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactSchema } from '../../validationSchema/contactSchema';
import { useDispatch, useSelector } from "react-redux";
import { contact, contactConstant } from '../../actions/contact.action';
import { useNavigate } from "react-router-dom";

const schema = contactSchema;

function ContactForm() {
    const contactState = useSelector((state) => state.contact);
    const { userDetails } = useSelector((state) => state.auth);

    const [focusedInput, setFocusedInput] = useState(null);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    useEffect(() => {
        document.title = 'Shopthumb | Contact Us'
        if (contactState.isSuccess === true) {
            dispatch(contact.resetState());

            return navigate("/contact-us-success");
        }
    }, [contactState?.isSuccess]);

    const onSubmit = (data) => {
        dispatch({ type: contactConstant.CONTACT_REQUEST });
        dispatch(contact.saveData(data));
    }

    const handleInputFocus = (fieldName) => {
        setFocusedInput(fieldName);
    }

    const handleInputBlur = () => {
        setFocusedInput(null);
    }

    const handleInput = (event) => {
        const input = event.target.value;

        // Allow only numbers and one '+' at the start
        const formattedInput = input.replace(/(?!^\+)[^0-9]/g, '');

        // Limit to a maximum of 15 characters including '+'
        if (formattedInput.startsWith('+')) {
            event.target.value = formattedInput.slice(0, 16); // '+xxxxxxxxxxxxxx' (1 + 15 digits)
        } else {
            event.target.value = formattedInput.slice(0, 15); // 'xxxxxxxxxxxxxxx' (15 digits max)
        }
    };

    return (
        <>
            <h3 className='mb-3'>Contact Us</h3>
            <p className='text-muted text-center mb-5'>
                We love getting emails from our customers. Please feel free to write to us using the form below. We will get back to you within 24 - 48 hours.
            </p>
            <form name="web-contact-form" onSubmit={handleSubmit(onSubmit)}>
                <div class="form-floating mt-20">
                    <input
                        type="text"
                        className={`form-control custom-input-floating ${errors?.full_name ? 'is-invalid' : ''}`}
                        id="floatingName"
                        autoComplete="off"
                        identity="full_name"
                        label="Full Name"
                        {...register('full_name')}
                        placeholder="name"
                        onFocus={() => handleInputFocus('full_name')}
                        onBlur={handleInputBlur}
                        minLength={3}
                        maxLength={25}
                        defaultValue={userDetails.first_name ? `${userDetails.first_name} ${userDetails.last_name}` : null}
                        autoFocus={userDetails.first_name ? false : true}
                    />
                    {focusedInput !== 'full_name' && errors?.full_name && (
                        <div className="invalid-feedback" style={{ color: '#d62727' }}>{errors?.full_name?.message}</div>
                    )}
                    <label htmlFor="floatingName" className="label-name">
                        Full Name
                    </label>
                </div><br /><br />

                <div class="form-floating mt-20">
                    <input
                        {...register('email')}
                        label="Email"
                        name="email"
                        type="email"
                        identity="email"
                        autoComplete="off"
                        className={`form-control custom-input-floating ${errors?.email ? "is-invalid" : ""}`}
                        id="floatingEmail"
                        placeholder="email"
                        onFocus={() => handleInputFocus('email')}
                        onBlur={handleInputBlur}
                        minLength={3}
                        maxLength={50}
                        defaultValue={userDetails.email ? `${userDetails.email}` : null}
                    />
                    {focusedInput !== 'email' && errors?.email && (
                        <div className="invalid-feedback" style={{ color: '#d62727' }}>{errors?.email.message}</div>
                    )}
                    <label for="floatingEmail" className='label-name'>Email</label>
                </div><br /><br />

                <div class="form-floating mt-20">
                    <input
                        type="text"
                        className={`form-control custom-input-floating ${errors?.phone ? "is-invalid" : ""}`}
                        id="floatingNumber"
                        autoComplete="off"
                        label="phone"
                        {...register('phone')}
                        name="phone"
                        placeholder="number"
                        onFocus={() => handleInputFocus('phone')}
                        onBlur={handleInputBlur}
                        maxLength={15}
                        // onInput={handleInput}
                        autoFocus={userDetails.email ? true : false}
                    />
                    {focusedInput !== 'phone' && errors?.phone && (
                        <div className="invalid-feedback" style={{ color: '#d62727' }}>{errors?.phone.message}</div>
                    )}
                    <label for="floatingNumber" className='label-name'>Phone Number</label>
                </div><br /><br />

                <div class="form-floating mt-20">
                    <input
                        {...register("subject")}
                        name="subject"
                        type="text"
                        autoComplete="off"
                        className={`form-control custom-input-floating ${errors?.subject ? "is-invalid" : ""}`}
                        id="floatingSubject"
                        placeholder="Subject"
                        onFocus={() => handleInputFocus('subject')}
                        onBlur={handleInputBlur}
                        minLength={3}
                        maxLength={100}
                    // value={'Testing for Logout'}
                    />
                    {focusedInput !== 'subject' && errors?.subject && (
                        <div className="invalid-feedback" style={{ color: '#d62727' }}>{errors?.subject.message}</div>
                    )}
                    <label for="floatingSubject" className='label-name'>Subject</label>

                </div><br /><br />

                <div class="form-floating mt-20">
                    <textarea
                        {...register('message')}
                        name="message"
                        autoComplete="off"
                        cols={4}
                        rows={6}
                        id="floatingMessage"
                        className={`form-control custom-input-floating ${errors?.message ? "is-invalid" : ""}`}
                        placeholder="Message"
                        onFocus={() => handleInputFocus('message')}
                        onBlur={handleInputBlur}
                        minLength={3}
                        maxLength={250}
                        style={{ overflow: 'hidden' }} // This hides the scrollbar
                    ></textarea>
                    {focusedInput !== 'message' && errors?.message && (
                        <div className="invalid-feedback" style={{ color: '#d62727' }}>{errors?.message.message}</div>
                    )}
                    <label for="floatingMessage" className='label-name'>Message</label>
                    {/* {errors?.message && <div className="invalid-feedback">{errors?.message.message}</div>} */}
                </div><br /><br />

                {contactState.submitted === true && (
                    <button className="button" disabled>
                        <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>{" "}
                        Loading...
                    </button>
                )}
                {contactState.submitted === false && (
                    <button for="floatingbutton" class="button-msg" type='submit'>SUBMIT</button>
                )}

            </form>
        </>
    )
}

export default ContactForm;