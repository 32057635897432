import { productService } from "../adapters/product.service";
import { toast } from "react-toastify";
import history from "../helpers/history";

export const productConstant = {
    LIST_HEADING: "Products",
    NEW_PRODUCT: "New PRODUCT",
    GET_PRODUCT_PENDING: "GET_PRODUCT_PENDING",
    GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
    GET_PRODUCT_FAILURE: "GET_PRODUCT_FAILURE",
    ADD_PRODUCT_PENDING: "ADD_PRODUCT_PENDING",
    ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
    ADD_PRODUCT_FAILURE: "ADD_PRODUCT_FAILURE",
    GET_DETAIL_SUCCESS: "GET_DETAIL_SUCCESS",
    GET_DETAIL_FAILURE: "GET_DETAIL_FAILURE",
    ADD_MEDIA_REQUEST: 'ADD_MEDIA_REQUEST',
    ADD_MEDIA_SUCCESS: 'ADD_MEDIA_SUCCESS',
    ADD_MEDIA_FAILURE: 'ADD_MEDIA_FAILURE',
    GET_MEDIA_SUCCESS: "GET_MEDIA_SUCCESS",
    GET_MEDIA_FAILURE: "GET_MEDIA_FAILURE",
    ADD_MEDIA_PENDING: "ADD_MEDIA_PENDING",
    DELETE_MEDIA_FAILURE: 'DELETE_MEDIA_FAILURE',
    DELETE_MEDIA_SUCCESS: "DELETE_MEDIA_SUCCESS",
    DELETE_ALLMEDIA_SUCCESS: "DELETE_ALLMEDIA_SUCCESS",
    PRIMARY_MEDIA_REQUEST: 'PRIMARY_MEDIA_REQUEST',
    PRIMARY_MEDIA_SUCCESS: 'PRIMARY_MEDIA_SUCCESS',
    PRIMARY_MEDIA_FAILURE: 'PRIMARY_MEDIA_FAILURE',

    GET_STORE_SUCCESS: "GET_STORE_SUCCESS",
    GET_STORE_FAILURE: "GET_STORE_FAILURE",

    GET_BRAND_SUCCESS: "GET_BRAND_SUCCESS",
    GET_BRAND_FAILURE: "GET_BRAND_FAILURE",

    GET_PRODUCT_BY_STORE_SUCCESS: "GET_PRODUCT_BY_STORE_SUCCESS",
    GET_PRODUCT_BY_STORE_FAILURE: "GET_PRODUCT_BY_STORE_FAILURE",
    GET_PRODUCT_BY_STORE_REQUEST: "GET_PRODUCT_BY_STORE_REQUEST",

    GET_PRODUCT_OPTION_ATTR_SUCCESS: "GET_PRODUCT_OPTION_ATTR_SUCCESS",
    GET_PRODUCT_OPTION_ATTR_FAILURE: "GET_PRODUCT_OPTION_ATTR_FAILURE",

    ADD_TO_FAVORITE_REQUEST: "ADD_TO_FAVORITE_REQUEST",
    ADD_TO_FAVORITE_SUCCESS: "ADD_TO_FAVORITE_SUCCESS",
    ADD_TO_FAVORITE_FAILURE: "ADD_TO_FAVORITE_FAILURE",

    GET_FAVORITES_SUCCESS: "GET_FAVORITES_SUCCESS",
    GET_FAVORITES_FAILURE: "GET_FAVORITES_FAILURE",
    GET_FAVORITES_REQUEST: "GET_FAVORITES_REQUEST",

    GET_SEARCH_DATA_REQUEST: "GET_SEARCH_DATA_REQUEST",
    GET_SEARCH_DATA_SUCCESS: "GET_SEARCH_DATA_SUCCESS",
    GET_SEARCH_DATA_FAILURE: "GET_SEARCH_DATA_FAILURE",

    GET_ADDTOFAV_FILTTER_SUCCESS: "GET_ADDTOFAV_FILTTER_SUCCESS",
    GET_ADDTOFAV_FILTTER_FAILURE: "GET_ADDTOFAV_FILTTER_FAILURE",
    GET_ADDTOFAV_FILTTER_REQUEST: "GET_ADDTOFAV_FILTTER_REQUEST",

    GET_COLOR_FAILURE: "GET_COLOR_FAILURE",
    GET_COLOR_SUCCESS: "GET_COLOR_SUCCESS"

};


export const productAction = {
    getProducts,
    getProductById,
    getMedia,
    getBrands,
    getStores,
    productOptionAttr,
    getSearchFilterValue,
    getProductByFilter,
    addToFavorite,
    getFavoritesData,
    getAddTOFavouriteFilter,
    getSearchKeyProduct,
    getColors,
};

function getProducts(params) {
    return (dispatch) => {
        dispatch(request());
        productService
            .getProducts(params)
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(errorProduct(error));
            });
    };

    function errorProduct(error) {
        return { type: productConstant.GET_PRODUCT_FAILURE, error: error };
    }

    function request() {
        return { type: productConstant.GET_PRODUCT_PENDING };
    }

    function success(data) {
        return { type: productConstant.GET_PRODUCT_SUCCESS, payload: data };
    }
}

function getProductById(id) {
    return (dispatch) => {
        productService
            .getProductById(id)
            .then((response) => {
                dispatch(success(response.data?.[0]));
            })
            .catch((error) => {
                dispatch(errorProduct(error));
            });
    };

    function errorProduct(error) {
        return { type: productConstant.GET_PRODUCT_FAILURE, error: error };
    }

    function success(data) {
        return { type: productConstant.GET_DETAIL_SUCCESS, payload: data };
    }
}


function getMedia(data) {
    return (dispatch) => {
        productService
            .getMedia(data)
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(errorProduct(error));
            });
    };

    function errorProduct(error) {
        return { type: productConstant.GET_MEDIA_FAILURE, error: error };
    }

    function success(data) {
        return { type: productConstant.GET_MEDIA_SUCCESS, payload: data };
    }
}

function getBrands() {
    return (dispatch) => {
        productService
            .getBrands()
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(errorProduct(error));
            });
    };

    function errorProduct(error) {
        return { type: productConstant.GET_BRAND_FAILURE, error: error };
    }

    function success(data) {
        return { type: productConstant.GET_BRAND_SUCCESS, payload: data };
    }
}

function getStores() {
    return (dispatch) => {
        productService
            .getStores()
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(errorProduct(error));
            });
    };

    function errorProduct(error) {
        return { type: productConstant.GET_STORE_FAILURE, error: error };
    }

    function success(data) {
        return { type: productConstant.GET_STORE_SUCCESS, payload: data };
    }
}
function getColors() {
    return (dispatch) => {
        productService
            .getColors()
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(errorProduct(error));
            });
    };

    function errorProduct(error) {
        return { type: productConstant.GET_COLOR_FAILURE, error: error };
    }

    function success(data) {
        return { type: productConstant.GET_COLOR_SUCCESS, payload: data };
    }
}

function productOptionAttr() {
    return (dispatch) => {
        productService
            .productOptionAttr()
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(errorProduct(error));
            });
    };

    function errorProduct(error) {
        return { type: productConstant.GET_PRODUCT_OPTION_ATTR_FAILURE, error: error };
    }

    function success(data) {
        return { type: productConstant.GET_PRODUCT_OPTION_ATTR_SUCCESS, payload: data };
    }
}

/* Filters */
function getProductByFilter(params) {
    return (dispatch) => {
        productService
            .getProductByFilter(params)
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(errorProduct(error));
            });
    };

    function errorProduct(error) {
        return { type: productConstant.GET_PRODUCT_BY_STORE_FAILURE, error: error };
    }

    function success(data) {
        return { type: productConstant.GET_PRODUCT_BY_STORE_SUCCESS, payload: data };
    }
}

/* Filter: getting store and STORE data here by search */
function getSearchFilterValue(params) {
    let constKeySuccess = productConstant.GET_STORE_SUCCESS;
    let constKeyFailure = productConstant.GET_STORE_FAILURE;

    if (params.filterType === 'brandList') {
        constKeySuccess = productConstant.GET_BRAND_SUCCESS;
        constKeyFailure = productConstant.GET_BRAND_FAILURE;
    }

    return (dispatch) => {
        dispatch(request());
        productService
            .getSearchFilterValue(params)
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(errorProduct(error));
            });
    };

    function errorProduct(error) {
        return { type: constKeyFailure, error: error };
    }

    function success(data) {
        return { type: constKeySuccess, payload: data };
    }

    function request() {
        return { type: constKeyFailure };
    }
}

/* Add to favorite product */
function addToFavorite(params, token) {
    return (dispatch) => {
        dispatch(request());
        productService
            .addToFavorite(params, token)
            .then((response) => {
                dispatch(success(response.data, params?.source));
            })
            .catch((error) => {
                dispatch(errorProduct(error));
            });
    };

    function errorProduct(error) {
        toast.error(error);
        return { type: productConstant.ADD_TO_FAVORITE_FAILURE, error: error };
    }

    function request() {
        return { type: productConstant.ADD_TO_FAVORITE_REQUEST };
    }

    function success(data, source) {
        if (source !== 'favorite_page') toast.success(data.message);
        return { type: productConstant.ADD_TO_FAVORITE_SUCCESS, payload: data };
    }
}

/* Getting all favorites data here... */
function getFavoritesData(params) {
    return (dispatch) => {
        productService
            .getFavoritesData(params)
            .then((response) => {
                // console.log(response);
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(errorProduct(error));
            });
    };

    function errorProduct(error) {
        return { type: productConstant.GET_FAVORITES_FAILURE, error: error };
    }

    function success(data) {
        return { type: productConstant.GET_FAVORITES_SUCCESS, payload: data };
    }
}
//getting onsale product here
function getAddTOFavouriteFilter(params) {
    return (dispatch) => {
        // dispatch(success(response.data));
        productService
            .getAddTOFavouriteFilter(params)
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(errorProduct(error));
            });
    };

    function errorProduct(error) {
        return { type: productConstant.GET_ADDTOFAV_FILTTER_FAILURE, error: error };
    }

    function success(data) {
        return { type: productConstant.GET_ADDTOFAV_FILTTER_SUCCESS, payload: data };
    }
}


/* Getting search key product here... */
function getSearchKeyProduct(params) {
    return (dispatch) => {
        dispatch(request());
        productService
            .getSearchKeyProduct(params)
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(errorCategory(error));
            });
    };

    function errorCategory(error) {
        return { type: productConstant.GET_SEARCH_DATA_FAILURE, error: error };
    }

    function request() {
        return { type: productConstant.GET_SEARCH_DATA_REQUEST };
    }

    function success(data) {
        return { type: productConstant.GET_SEARCH_DATA_SUCCESS, payload: data };
    }
}