import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { profileSchema } from '../../../validationSchema/profileSchema';
import { FormInput } from '../../../../src/utility/FormInput';
import { auth, authConstant } from '../../../actions/auth.action';
import Message from '../../../components/Message/Message';
import Sidebar from '../../../utility/Sidebar';
import './style.css';

const schema = profileSchema;

function Profile() {
    const authState = useSelector((state) => state.auth);
    const [showPassword, setShowPassword] = useState(true);
    const [isEditing, setIsEditing] = useState(false);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    useEffect(() => {
        document.title = 'Shopthumb | Profile';
        if (!authState.userDetails.id) {
            navigate("/");
        }
    }, [authState, navigate]);

    const logOut = () => {
        dispatch(auth.signOut({ Token: authState.userDetails.token }));
    };

    const onSubmit = (data) => {
        dispatch({ type: authConstant.UPDATE_REQUEST });
        dispatch(auth.updateProfile(data, { Token: authState.userDetails.token }));
        // setIsEditing(false); // Disable editing after update
    };

    const togglePassword = () => {
        setShowPassword(!showPassword);
        const loginPasswordEl = document.getElementById('password');
        if (loginPasswordEl) {
            loginPasswordEl.type = showPassword ? 'text' : 'password';
        }
    };

    return (
        <div>
            <div className="container mb-5">
                <div className="row">
                    <Sidebar authState={authState} logOut={logOut} component='profile' />

                    <div className="col-md-10">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade bg-white show active p-4" id="v-pills-acc" role="tabpanel" aria-labelledby="v-pills-acc-tab">
                                <h5 className="mb-5 text-center text-uppercase ui">Your Information</h5>
                                {authState.error.type === 'profile' && <Message />}

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {/* Move the Edit button above the form */}
                                    {!isEditing ? (
                                        <button type="button" className="btn btn-dark signin mb-3" onClick={() => setIsEditing(true)} style={{ marginLeft: '90%', marginTop: '-57px', width: '10%' }}>
                                            Edit
                                        </button>
                                    ) : null}



                                    {/* First Name & Last Name Fields */}
                                    <div className="form-row mb-3 mx-0">
                                        <div className="form-group position-relative col-lg-6 mb-3 px-0">
                                            <FormInput
                                                register={register('first_name')}
                                                label="First Name"
                                                error={errors?.first_name}
                                                name="first_name"
                                                type="text"
                                                autoComplete="off"
                                                identity="first_name"
                                                defaultValue={authState.userDetails.first_name}
                                                readOnly={!isEditing}
                                            />
                                        </div>

                                        <div className="form-group position-relative col-lg-6">
                                            <FormInput
                                                register={register('last_name')}
                                                label="Last Name"
                                                error={errors?.last_name}
                                                name="last_name"
                                                type="text"
                                                identity="last_name"
                                                autoComplete="off"
                                                defaultValue={authState.userDetails.last_name}
                                                readOnly={!isEditing}
                                            />
                                        </div>
                                    </div>

                                    {!isEditing &&
                                        <div className="form-group position-relative" style={{marginBottom: '28px'}}>
                                            <FormInput
                                                register={register('email')}
                                                label="Email"
                                                error={errors?.email}
                                                name="email"
                                                type="email"
                                                autoComplete="off"
                                                identity="email"
                                                defaultValue={authState.userDetails.email}
                                                readOnly={true} // Always read-only
                                            />
                                        </div>}

                                    {/* Password Field */}
                                    <div className="form-group position-relative" id="show_hide_password">
                                        <FormInput
                                            register={register('password')}
                                            label="Password"
                                            error={errors?.password}
                                            name="password"
                                            type="password"
                                            identity="password"
                                            autoComplete="off"
                                            id="password"
                                            defaultValue={authState.userDetails.password}
                                            readOnly={!isEditing}
                                        />
                                        <div className="input-group-addon">
                                            <a href onClick={(e) => { e.preventDefault(); togglePassword(); }}>
                                                <i className={`fa fa-eye${showPassword ? '-slash' : ''}`} aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </div>

                                    {/* Update Button (Same Position) */}
                                    {isEditing && (
                                        <button type="submit" className="btn btn-dark signin mb-2">Update</button>
                                    )}
                                </form>

                            </div>
                            <div class="tab-pane fade shadow rounded bg-white p-4" id="v-pills-cashback" role="tabpanel" aria-labelledby="v-pills-cashback-tab">
                                <h4 class=" mb-4">Cashback</h4>
                                <p class=" text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>

                            <div class="tab-pane fade shadow rounded bg-white p-4" id="v-pills-looks" role="tabpanel" aria-labelledby="v-pills-looks-tab">
                                <h4 class=" mb-4">Looks</h4>
                                <p class=" text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>

                            <div class="tab-pane fade shadow rounded bg-white p-4" id="v-pills-closet" role="tabpanel" aria-labelledby="v-pills-closet-tab">
                                <h4 class=" mb-4">Closet</h4>
                                <p class=" text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
